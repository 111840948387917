import './App.css';
import withRoot from './HOC/withRoot';
import { useTranslation } from 'react-i18next';
import getWebsiteData from './HOC/getWebsiteData';
import useScrollToTop from 'Components/helperFunctions/scrollToTop';

import React from 'react';

const clientName = process.env.REACT_APP_CLIENT_NAME || 'mountain';

const Client = React.lazy(() => import(`./Clients/${clientName}/Index`));

function App() {
  useScrollToTop();

  const { i18n } = useTranslation();
  document.getElementsByTagName("html")[0].setAttribute("dir", i18n.dir());

  return <Client />
}

export default getWebsiteData(withRoot(App));