interface Name {
    ar: string;
    en: string;
}

interface App {
    basePath: string;
    siteTitle: string;
    languages: string[];
    name: Name;
    key: string;
    themeMode?: string;
}

interface Theme {
    primaryDark: string;
    primaryLight: string;
    secondaryDark: string;
    secondaryLight: string;
    loadingColor?: string;
}
interface SocialLinks {
    name: string;
    link: string;
}

export interface websiteTypes {
    app: App;
    theme: Theme;
    socialLinks: SocialLinks[];
}

export interface ConfigType {
    [key: string]: websiteTypes;
}

export class InitWebData {
    app: App;
    theme: Theme;
    socialLinks: SocialLinks[];

    constructor(data: websiteTypes) {
        this.app = data.app;
        this.theme = data.theme;
        this.socialLinks = data.socialLinks;
    }
    // Add any methods to manipulate or retrieve the data if necessary
}
