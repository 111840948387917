import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { colorSlice } from "./features/color";
import { darkModeSlice } from "./features/darkMode";
import { websiteDataSlice } from "./features/websiteData";

export const store = configureStore({
    reducer: {
        color: colorSlice.reducer,
        dark: darkModeSlice.reducer,
        websiteData: websiteDataSlice.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                ignoredActions: ['websiteData/changeWebsiteData'],
                // Ignore these field paths in all actions
                ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
                // Ignore these paths in the state
                ignoredPaths: ['websiteData.value'],
            },
        }),
});
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
