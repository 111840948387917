import React, { FunctionComponent, useEffect, useState } from "react";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import * as color from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import rtlPlugin from "stylis-plugin-rtl";
import { CssBaseline, Stack } from "@mui/material";
import { useAppSelector } from "../store/store";
import { prefixer } from "stylis";
import { FallingLines } from "react-loader-spinner";

export const muiCache = createCache({
    key: "mui",
});

const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
});
interface WithRootProps {
    // Add any props you want to pass to the wrapped component
}

function withRoot<P extends WithRootProps>(Component: FunctionComponent<P>): FunctionComponent<P> {
    const WithRoot: FunctionComponent<P> = (props) => {
        const { i18n } = useTranslation();

        const updateColor = useAppSelector((state) => state.color);
        const darkMode = useAppSelector((state) => state.dark).value;
        const websiteData = useAppSelector((state) => state.websiteData).value;

        const [isLoading, setIsLoading] = useState(true);

        useEffect(() => {
            setTimeout(() => {
                setIsLoading(false);
            }, 3000);
        }, []);

        if (isLoading) {
            return <Stack className={`custom-loading ${!isLoading && ' fadeOut'}`} height={"100dvh"} width={"100%"} justifyContent={"center"} alignItems={"center"} >
                <FallingLines
                    color={websiteData.theme.loadingColor ?? websiteData.theme.primaryLight}
                    width="50"
                    visible={true}
                />
            </Stack>
        }

        const primaryColor = darkMode ? websiteData.theme.primaryDark : websiteData.theme.primaryLight;
        const primaryColorKey = primaryColor as keyof typeof color;
        const secondaryColor = darkMode ? websiteData.theme.secondaryDark : websiteData.theme.secondaryLight;
        const secondaryColorKey = secondaryColor as keyof typeof color;

        const theme = createTheme({
            direction: i18n.dir(),

            components: {
                MuiIcon: {
                    styleOverrides: {
                        root: {
                            fontFamily: "'Material Icons Outlined' !important",
                        },
                    },
                },
                MuiToolbar: {
                    styleOverrides: {
                        root: {
                            textTransform: "capitalize",
                        },
                    },
                },
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            padding: "0 16px",
                            maxHeight: "45px",
                            height: "45px",
                            whiteSpace: "nowrap",
                        },
                        head: {
                            // fontWeight: 600,
                        },
                    },
                },
                MuiSelect: {
                    defaultProps: {
                        variant: "filled",
                    },
                },
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            backgroundImage: "none",
                        },
                    },
                },
                MuiFormControl: {
                    styleOverrides: {
                        root: {
                            "& label ,& input ,& .MuiSelect-select": {
                                // fontSize: "0.800rem",
                            },
                        },
                    },
                },
                MuiTextField: {
                    defaultProps: {
                        size: "small"
                    }
                }
            },

            palette: {
                mode: darkMode ? 'dark' : 'light',
                primary: {
                    main: updateColor.value ? updateColor.value : primaryColor.startsWith("#")
                        ? primaryColor
                        : color[primaryColorKey][500 as keyof typeof color[typeof primaryColorKey]],
                },
                secondary: {
                    main: secondaryColor.startsWith("#")
                        ? secondaryColor
                        : color[secondaryColorKey][500 as keyof typeof color[typeof secondaryColorKey]],
                },
                ...(darkMode ? {
                    background: {
                        default: "#18191a",
                        paper: "#2f3031",
                        // hover: "rgba(73, 73, 73)",
                        // appTitle: "rgba(73, 73, 73)"
                    }
                }
                    : {
                        background: {
                            default: "#fafafa",
                            paper: "#fff",
                            // hover: "#f5f5f5",
                            // appTitle: "#f5f7f9"
                        }
                    })
            },
            typography: {
                fontFamily: ['"Cairo"', "sans-serif"].join(","),
                fontSize: 12.5,
            },
        });

        return (
            <CacheProvider value={i18n.dir() === "rtl" ? cacheRtl : muiCache}>
                <ThemeProvider
                    theme={
                        i18n.dir() === "rtl"
                            ? {
                                ...theme,

                                direction: "rtl",
                            }
                            : {
                                ...theme,

                                direction: "ltr",
                            }
                    }
                >
                    <CssBaseline />
                    <div className="website fadeIn">
                        <Component {...props} />
                    </div>
                </ThemeProvider>
            </CacheProvider>
        );
    };

    return WithRoot;
}

export default withRoot;
