import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const clintLanguages = process.env.REACT_APP_CLIENT_LANGS ? JSON.parse(process.env.REACT_APP_CLIENT_LANGS) : ['en'];

const Languages = clintLanguages;
console.log(Languages);

localStorage.setItem("i18nextLng", Languages.length === 1 ? Languages[0] : localStorage.getItem("i18nextLng") ?? Languages[0])

const path = ""

i18n
    .use(Backend)
    .use(LanguageDetector)
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: Languages,
        debug: false,
        backend: {
            loadPath:
                `${path}/locales/{{lng}}/{{ns}}.json?cb=` + new Date().getTime(),
        },
    });

export default i18n;
