import { FunctionComponent } from "react";
import config from "../websiteData.json";
import { useAppDispatch } from "../store/store";
import { changeWebsiteData } from "../store/features/websiteData";
import { InitWebData } from "../types";


interface GetWebsiteDataProps {
    // Add any props you want to pass to the wrapped component
}

const clientName = process.env.REACT_APP_CLIENT_NAME || 'mountain';

function getWebsiteData<P extends GetWebsiteDataProps>(Component: FunctionComponent<P>): FunctionComponent<P> {
    const GetWebsiteData: FunctionComponent<P> = (props) => {
        const dispatch = useAppDispatch();

        const siteConfig = (config as any)?.[clientName];
        if (siteConfig) {
            const conf = new InitWebData(siteConfig)
            dispatch(changeWebsiteData(conf))
        }
        // const [manifest, setManifest] = useState(null);

        return (
            <div>
                <Component {...props} />
            </div>
        );
    };

    return GetWebsiteData;
}

export default getWebsiteData;
